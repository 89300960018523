<template>
<div>
    <div v-show="isLoading" class="preloader"></div>
    <div class="row align-item-center">
        <div class="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat" style="background-image: url(/images/bg/sec1-maskot.png);"></div>
        <div class="col-xl-7 vh-lg-100 align-items-center d-flex bg-white rounded-lg overflow-hidden">
            <div class="card shadow-none border-0 ml-auto mr-auto login-card">
                <div class="card-body rounded-0 text-left">
                    <div class="text-center mb-3">
                        <img :src="'/images/logo.png'" width="80" height="80" class="rounded" alt="logo">
                    </div>
                    <form @submit.prevent="onLogin"> 
                        <div class="form-group icon-input mb-3">
                            <i class="font-sm ti-email fw-600 text-grey-700 pr-0"></i>
                            <input v-model="email" type="text" required class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Email / Username">                        
                        </div>
                        <div class="form-group icon-input mb-3">
                            <input v-model="password" :type="isVisible ? 'text' : 'password'" required class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" placeholder="Password">
                            <i class="font-sm ti-lock fw-600 text-grey-700 pr-0"></i>
                        </div>
                        <div class="form-check text-left mb-4">
                            <input type="checkbox" v-model="isVisible" class="form-check-input mt-2" id="exampleCheck2">
                            <a href="javascript:void(0)" @click="isVisible = !isVisible" class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck2">Show Password</a>
                        </div>
                        
                        
                    <div class="col-sm-12 p-0 text-left">
                        <div class="mb-1">
                            <input type="submit" class="btn-current text-center style2-input fw-600 border-lg p-0 form-control" value="Sign in">
                        </div>
                        <h6 class="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32 text-center">Don't have account <router-link to="/register" class="fw-700 ml-1 text-warning">Register</router-link></h6>
                        <h6 class="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32 text-center"><router-link to="/forgot-password" class="fw-700 ml-1 text-warning">Forgot your password?</router-link></h6>
                        <h6 class="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32 text-center"><router-link to="/browse" class="fw-700 ml-1 text-warning">Back to Homepage</router-link></h6>
                    </div>
                    </form>
                </div>
            </div> 
        </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "LoginComponent",
        data() {
            return {
                email: '',
                password: '',
                isLoading: false,
                isVisible: false,
                isShow: false,
                alertMsg: '',
                alertType: ''
            }
        },
        methods: {
            onLogin() {
                this.isLoading = true
                axios.post(`${process.env.VUE_APP_URL_API}/login`, {
                    username: this.email,
                    password: this.password,
                }
                ).then(response => {
                    localStorage.setItem('token', response.data.token)
                    localStorage.setItem('user', JSON.stringify(response.data.user))
                    if (response.data.user.email_verified_at == null) { 
                        this.$router.push({ name: 'Verification' })
                    } else {
                        this.$router.push({ name: 'GettingStarted' })
                    }
                    this.$swal({
                        toast: true,
                        title: 'Sign in success!',
                        text: "You're signed in",
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }).catch(error => {
                    this.isLoading = false
                    this.$swal({
                        toast: true,
                        title: 'Sign In Failed !',
                        text: error.response.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                })
            }
        }       
    }
</script>
